<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        Reflect Internationally, Anchor Nationally
      </h2>
      <p class="desc">
        To deliver the on-point execution and to deliver ideal speed to your
        customers, try using the innovative framework of Ejaf. Tactically found
        and fueled by two namely data centers in Canada with association
        provided by effective and open systems the services you seek are sure to
        be found by using our application
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/hosting-solutions/hosting-solutions.png"
        alt="Reflect Internationally, Anchor Nationally"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-hosting-section",
};
</script>
