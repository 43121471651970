<template>
  <hosting-solutions />
</template>

<script>
import HostingSolutions from "../../components/services/HostingSolutions.vue";
export default {
  components: { HostingSolutions },
};
</script>
