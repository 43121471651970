<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">
        North America Top-rated 24 / 7 / 365 Technical Support
      </h2>
      <p class="desc">
        To deliver the valuable business, Iraq’s top-ranking high-tech
        sponsorship is here for your structure. The most well-known approach set
        it and forget it doesn’t work here. Because if you want to achieve a
        high level you have to look after that, manage it effectively, and
        optimize it. But as EJAF s here for you, you don’t have to worry about
        its optimization and management, as all the services offered by EJAF are
        completely managed meaning that you will get completely arranged and
        optimized hosting conditions that will be specially modified for your
        project. And the most amazing thing is that we don’t charge extra for it
        and the 24/7 high tech support provided by our experts is cherry on the
        top.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/hosting-solutions/24-7.png"
        alt="North America Top-rated 24 / 7 / 365 Technical Support"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fiveth-hosting-section",
};
</script>
