<template>
  <div class="modifying my-5 py-5 text-center">
    <ul class="row">
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-clock fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Execution</h4>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-clock fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Accessibility</h4>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-maximize fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Marketability</h4>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "second-hosting-section",
};
</script>
