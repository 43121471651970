<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Invest your Application and Business</h2>
      <p class="desc">
        With Ejaf technology, it does not matter where you decide to host. The
        performance you expect, your application’s dependability as per need
        with minimum low latency rate, is what we offer. Our (SAN), i.e.,
        enclosure network, provides safe transportation for scalable mass
        storage volumes.
      </p>
      <h3>Three Liberated Networks</h3>
      <ul class="desc">
        <li>
          <p>Rate of Public Network out is up to 500 Mbps</p>
        </li>
        <li>
          <p>Safe and Secure Out of Band Private Network</p>
        </li>
        <li>
          <p>Storage Area Network (SAN) is independent</p>
        </li>
      </ul>
      <h3>Content Distribution Network</h3>
      <ul class="desc">
        <li>
          <p>Spontaneous content delivery</p>
        </li>
        <li>
          <p>DNS and DDoS Protection</p>
        </li>
        <li>
          <p>Uptime observing with SMS forewarns</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/hosting-solutions/hosting-solutionsimg-1.png"
        alt="Invest your Application and Business"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "third-hosting-section",
};
</script>
