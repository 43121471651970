<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Simple Explanations for Complex Ventures</h2>
      <p class="desc">
        We provide not only the hardware that our customers need to know but
        also the methodological support that they need. We do this so that this
        step helps our customers in achieving their goals effectively and
        efficiently.
      </p>
      <h3>Explore The Options Ejaf Gives you</h3>
      <ul class="desc">
        <li>
          <p>HTTP Load Corresponding</p>
        </li>
        <li>
          <p>Offloaded MySQL facility</p>
        </li>
        <li>
          <p>SOffsite Holdup for acute information</p>
        </li>
        <li>
          <p>Custom Delivery and Kernel Maintenance</p>
        </li>
      </ul>
      <h3>Gears to Assist You in Completing the Task</h3>
      <ul class="desc">
        <li>
          <p>Complete Source SSH right of entry</p>
        </li>
        <li>
          <p>Permitted Server Observing</p>
        </li>
        <li>
          <p>24/7 checking with SMS alerts</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/hosting-solutions/hosting-solutionsimg-2.png"
        alt="Simple Explanations for Complex Ventures"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fourth-hosting-section",
};
</script>
