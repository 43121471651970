<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        Discover Additional Information as to How Dedicated the Ejaf Hosting
        Solution Is
      </h2>
      <p class="desc">
        The ability to save the cost along with speed as well as better output
        and effective results is what triggers the option of adopting cloud
        computing. It provides many benefits in comparatively less amount. When
        clients select the option of cloud computing, they discover with time
        that it has many other benefits like increased productivity, better
        hosting solutions service, best quality, high safety, and security of
        data.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/hosting-solutions/unnamed.png"
        alt="Discover Additional Information as to How Dedicated the Ejaf Hosting Solution Is"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-hosting-section",
};
</script>
